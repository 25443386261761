<template>
    <div class="article-category-setting">
        <md-card>
            <md-card-header>Article Category Setting</md-card-header>
            <md-card-content></md-card-content>
        </md-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            setting: {},
            groupCategories: {}
        };
    },
    methods: {
        async loadSetting() {
            let result = await this.$store.dispatch("get", {
                api: "settings/article-category"
            });
            let value = result.value;
            this.setting = value || {};

            // let sample = {
            //     name: "",
            //     slug: "",
            //     categories: [{ name: "", slug: "", rank: 1 }]
            // };
        },
        async getGroupCategories() {
            let result = await this.$store.dispatch("crud/get", {
                api: "articles/categories",
                params: {
                    group: 1
                }
            });
            this.groupCategories = result;
        },
        async saveSetting() {
            let groupCategories = this.groupCategories;
            for (let i in groupCategories) {
                let group = groupCategories[i];
                for (let j = 0; j < group.categories.length; j++) {
                    let category = group.categories[j];
                    category.rank = j;
                }
            }
            let result = await this.$store.dispatch("crud/put", {
                api: "settings/article-category",
                data: this.groupCategories
            });
            this.$store.commit("setMessage", "Save Success");
        },
        getCategoryBySlug(groups, pslug, slug) {
            if (groups) {
                for (let i in groups) {
                    let group = groups[i];
                    if (group.categories && group.slug == pslug) {
                        for (let j in group.categories) {
                            let category = group.categories[j];
                            if (category.slug == slug) {
                                return category;
                            }
                        }
                    }
                }
            }
            return null;
        },
        sortCategories() {
            let setting = this.setting;
            let groupCategories = this.groupCategories;
            for (let i in groupCategories) {
                let group = groupCategories[i];
                let pslug = group.slug;
                if (group.categories) {
                    for (let j in group.categories) {
                        let category = group.categories[j];
                        let slug = category.slug;
                        let categorySetting = this.getCategoryBySlug(setting, pslug, slug);
                        if (categorySetting && categorySetting.rank != undefined) {
                            // this.$set(category, "rank", categorySetting.rank);
                            category.rank = category.rank;
                        }
                    }
                    group.categories.sort(function(a, b) {
                        return a.rank - b.rank ? 1 : -1;
                    });
                }
            }
        }
    },
    async created() {
        await this.getGroupCategories();
        await this.loadSetting();
        this.sortCategories();
    }
};
</script>
