<template>
    <div class="article-index">
        <md-tabs @change="tabChange" ref="tabs">
            <md-tab :md-active="tabIndex==0" md-label="Articles">
                <article-list v-if="tabIndex==0"></article-list>
            </md-tab>
            <md-tab :md-active="tabIndex==1" :md-disabled="true" md-label="Categories">
                <article-category-setting v-if="tabIndex==1"></article-category-setting>
            </md-tab>
            <md-tab :md-active="tabIndex==2" md-label="New Article">
                <article-edit :id="id" v-if="tabIndex==2"></article-edit>
            </md-tab>
            <md-tab :md-active="tabIndex==3" :md-disabled="page!='edit'" md-label="Edit Article">
                <article-edit :id="id" v-if="tabIndex==3"></article-edit>
            </md-tab>
        </md-tabs>
    </div>
</template>
<script>
import ArticleList from "@/components/Article/ArticleList";
import ArticleEdit from "@/components/Article/ArticleEdit";
import ArticleCategorySetting from "@/components/Article/ArticleCategorySetting";
export default {
    name: "article-index",
    props: {
        page: {
            type: String,
            default: "list"
        },
        id: {
            type: String,
            default: ""
        }
    },
    components: {
        ArticleList,
        ArticleEdit,
        ArticleCategorySetting
    },
    data() {
        return {
            tabIndex: 0,
            ready: false
        };
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                switch (this.page) {
                    case "list":
                        this.tabIndex = 0;
                        break;
                    case "category":
                        this.tabIndex = 1;
                        break;
                    case "new":
                        this.tabIndex = 2;
                        break;
                    case "edit":
                        this.tabIndex = 3;
                        break;
                }

                setTimeout(() => {
                    this.ready = true;
                }, 0);
            }
        }
    },
    methods: {
        tabChange(index) {
            if (!this.ready) {
                return;
            }

            if (index == 0) {
                this.$router.push("/admin/articles");
            }
            if (index == 1) {
                this.$router.push(`/admin/articles/categories`);
            }
            if (index == 2) {
                this.$router.push(`/admin/articles/new`);
            }
            if (index == 3) {
                this.$router.push(`/admin/articles/${this.id}`);
            }
        }
    }
};
</script>

