<template>
    <div class="article-list">
        <list :fs="articleListFields" apiList="articles" apiRemove="articles" editLink="/admin/articles" title="Article List"></list>
    </div>
</template>

<script>
import List from "@/components/List.vue";
import { articleListFields } from "@/config.js";
export default {
    components: {
        List
    },
    computed: {
        articleListFields() {
            return articleListFields;
        }
    }
};
</script>
