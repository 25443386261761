<template>
    <div class="article-edit">
        <!-- <md-input-container>
            <autocomplete :options="['a','bbb','ccc']" fieldName="name" v-model="test"></autocomplete>
        </md-input-container>-->
        <edit :fs="articleEditFields" :id="id" @changed="changed" @saved="saved" api="articles" title="Edit Article"></edit>
    </div>
</template>
<script>
import Autocomplete from "@/components/Autocomplete";
import { articleEditFields } from "@/config.js";
import Edit from "@/components/Edit";
import Submit from "@/components/Submit";

export default {
    props: {
        id: {
            type: String
        }
    },
    components: {
        Edit,
        Submit,
        Autocomplete
    },
    data() {
        return {
            test: "",
            categories: [],
            newRecord: {},
            oldRecord: {}
        };
    },
    computed: {
        articleEditFields() {
            let fs = JSON.parse(JSON.stringify(articleEditFields));
            let categoryField = fs.find(a => a.data == "category_name");
            categoryField.options = this.categories;
            return fs;
        }
    },
    methods: {
        async changed(record) {
            this.oldRecord = JSON.parse(JSON.stringify(this.newRecord));
            this.newRecord = JSON.parse(JSON.stringify(record));
            let n = this.newRecord;
            let o = this.oldRecord;
            if (n.category_parent_name != o.category_parent_name) {
                console.info(n.category_parent_name, o.category_parent_name);
                await this.loadArticleCategories(n.category_parent_name);

                setTimeout(() => {
                    if (n.category_parent_name && o.category_parent_name) {
                        // record.category_name = "";
                    }
                }, 0);
            }

            if (n.category_name != o.category_name) {
                let category = this.categories.find(c => c.name == n.category_name);
                if (category) {
                    record.category_rank = category.rank || 0;
                }
            }
        },
        saved() {
            this.$router.push("/admin/articles");
        },
        async loadArticleCategories(parent) {
            let result = await this.$store.dispatch("crud/get", {
                api: "articles/categories",
                params: {
                    group: 1
                }
            });
            let groupCategories = result;
            for (let i in groupCategories) {
                let group = groupCategories[i];
                if (group.name == parent || group.slug == parent) {
                    this.categories = group.categories || [];
                }
            }
        }
    },
    created() {
        // this.loadArticleCategories();
    }
};
</script>
<style lang="less">
.article-edit {
    .md-input-container {
        input {
            font-size: 17px;
        }
    }
}
</style>

